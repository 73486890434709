<template>
  <img
    :src="src"
    width="183"
    height="129"
    style="margin-right: 16px"
    alt="monitor"
  />
</template>

<script>
export default {
  //组件注册
  components: {},
  //组件传值
  props: {
    uuid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      src: require('@/assets/screen/bj/monitor.png'),
      timer: null,
      refreshTime: 1000 * 30,
    };
  },
  //计算属性
  computed: {},
  //属性监听
  watch: {
    uuid() {
      this.timedRefresh();
    },
  },
  //DOM访问
  mounted() {
    this.timedRefresh();
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  //保存方法
  methods: {
    timedRefresh() {
      if (this.uuid) {
        this.getCameraImg();
        this.timer = setInterval(this.getCameraImg, this.refreshTime);
      }
    },
    async getCameraImg() {
      const data = await this.$http.get(
        `/${this.$businessISG}/monitor/hall/camera-img/${this.uuid}`
      );
      if (data.code == 200 && data.data) {
        this.src = data.data[0];
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
